/**
  HOW TO USE THIS THEME
  ----------------
  1. Import this file once in your top level app stylesheet, eg: apps/chcp/src/chcp-styles.scss
  2. Add these styles in your app project.json "styles" array. Replace "tokens_chc.css" with appropriate leaf brand name.
    styles: [
      "node_modules/@esi/leaf-design-tokens/lib/web/brands/chc/css/tokens_chc.css",
      "node_modules/@esi/leaf-wc/lib/design-tokens/core/scss/theming/head.scss",
    ]
  3. Optionally add class to body element in index.html: <body class="theme-leaf">. This allows for overriding in component css if needed using :host-context(.theme-leaf) {
**/

/* hopefully we can remove
@import '../../../slider-ui/src/lib/slider-theme';
*/

:root {
  /* global theme variables */
  --theme-global-link-color: var(--leaf-color-content-link);
  --theme-global-text-color: var(--leaf-color-content-default);
  --theme-global-body-font: var(--leaf-typography-body-default-font-family);
  --theme-global-heading-font: var(
    --leaf-typography-headline-default-font-family
  );

  /* libs/ui/input */
  --theme-input-border: 1px solid var(--leaf-color-border-strong);
  --theme-input-border-radius: var(--leaf-border-radius-default);
  --theme-input-border-focus: 1px solid var(--leaf-color-border-default);
  --theme-input-border-invalid: 1px solid var(--leaf-color-border-error);
  --theme-input-outline-focus: var(--leaf-border-width-focus) solid
    var(--leaf-color-border-active-outline);
  --theme-input-outline-focus-error-color: var(--leaf-color-border-error);
  --theme-input-outline-focus-offset: calc(var(--size-base-unit) * 0.25);

  --theme-input-padding: calc(var(--size-base-unit) * 1.5)
    calc(var(--size-base-unit) * 1);
  --theme-input-text-color: var(--leaf-color-content-default);

  --theme-input-box-shadow: none;
  --theme-input-box-shadow-invalid: none;
  --theme-input-box-shadow-focus: none;

  --theme-input-text-height: auto;
  --theme-input-font-size: var(--leaf-typography-body-default-font-size);
  --theme-input-line-height: var(--leaf-typography-body-default-line-height);
  --theme-input-font-weight: var(--leaf-typography-body-default-font-weight);
  --theme-input-font-family: var(--leaf-typography-body-default-font-family);

  --theme-input-select-height: #{rem-convert(50px)};
  --theme-input-select-font-weight: var(
    --leaf-typography-body-default-font-weight
  );
  --theme-input-select-appearance: none;
  --theme-input-select-background-img: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 6.83317L0 1.83317L1.16667 0.666504L5 4.49984L8.83333 0.666504L10 1.83317L5 6.83317Z' fill='currentColor'/%3E%3C/svg%3E%0A");

  /* libs/ui/button */
  --theme-button-font: var(--leaf-typography-label-default-font-size)
    var(--leaf-typography-label-default-font-family);
  --theme-button-font-weight: var(--leaf-typography-label-default-font-weight);
  --theme-button-border-radius: var(--leaf-border-radius-button);
  --theme-button-padding: var(--leaf-button-padding-vertical-medium)
    var(--leaf-button-padding-horizontal-medium);
  --theme-button-text-transform: var(--leaf-typography-label-default-text-case);
  --theme-button-transition: none;
  --theme-button-focus-shadow: none;

  --theme-button-small-font-size: var(
    --leaf-typography-label-default-font-size
  );
  --theme-button-small-padding: var(--leaf-button-padding-vertical-small)
    var(--leaf-button-padding-horizontal-small);

  --theme-button-link-text: var(--leaf-color-content-link);
  --theme-button-link-font: var(--leaf-typography-body-default-link-font-size)
    var(--leaf-typography-body-default-link-font-family);
  --theme-button-link-font-weight: var(
    --leaf-typography-body-default-font-weight
  );
  --theme-button-link-text-hover: var(--leaf-color-content-link-hover);
  --theme-button-link-underline: underline;
  --theme-button-link-underline-offset: 1px;
  --theme-button-link-inline-color: var(--leaf-color-content-link);
  --theme-button-link-inline-hover-color: var(--leaf-color-content-link-hover);
  --theme-button-link-inline-hover-border: var(--leaf-color-content-link-hover);
  --theme-button-link-inline-visited-color: var(--leaf-color-content-link);
  --theme-button-link-inline-visited-border: var(--leaf-color-content-link);

  --theme-button-primary-text: var(--leaf-color-button-primary-content);
  --theme-button-primary-border: var(--leaf-border-width-button-default) solid
    var(--leaf-color-button-primary-border);
  --theme-button-primary-fill: var(--leaf-color-button-primary-bg);
  --theme-button-primary-hover-text: var(
    --leaf-color-button-primary-content-hover
  );
  --theme-button-primary-hover-border: var(--leaf-border-width-button-default)
    solid var(--leaf-color-button-primary-border-hover);
  --theme-button-primary-hover-fill: var(--leaf-color-button-primary-bg-hover);
  --theme-button-primary-disabled-text: var(
    --leaf-color-button-primary-content-disabled
  );
  --theme-button-primary-disabled-border: var(
      --leaf-border-width-button-default
    )
    solid var(--leaf-color-button-primary-border-disabled);
  --theme-button-primary-disabled-fill: var(
    --leaf-color-button-primary-bg-disabled
  );
  --theme-button-primary-shadow: none;
  --theme-button-primary-focus-shadow: none;
  --theme-button-primary-active-outline: var(--leaf-border-width-focus) solid
    var(--leaf-color-border-active-outline);
  --theme-button-primary-active-outline-offset: calc(
    var(--size-base-unit) * 0.25
  );

  --theme-button-secondary-text: var(--leaf-color-button-secondary-content);
  --theme-button-secondary-border: var(--leaf-border-width-button-default) solid
    var(--leaf-color-button-secondary-border);
  --theme-button-secondary-fill: var(--leaf-color-button-secondary-bg);
  --theme-button-secondary-hover-text: var(
    --leaf-color-button-secondary-content-hover
  );
  --theme-button-secondary-hover-border: var(--leaf-border-width-button-default)
    solid var(--leaf-color-button-secondary-border-hover);
  --theme-button-secondary-hover-fill: var(
    --leaf-color-button-secondary-bg-hover
  );
  --theme-button-secondary-disabled-text: var(
    --leaf-color-button-secondary-content-disabled
  );
  --theme-button-secondary-disabled-border: var(
      --leaf-border-width-button-default
    )
    solid var(--leaf-color-button-secondary-border-disabled);
  --theme-button-secondary-disabled-fill: var(
    --leaf-color-button-secondary-bg-disabled
  );
  --theme-button-secondary-shadow: none;
  --theme-button-secondary-focus-shadow: none;
  --theme-button-secondary-active-outline: var(--leaf-border-width-focus) solid
    var(--leaf-color-border-active-outline);
  --theme-button-secondary-active-outline-offset: calc(
    var(--size-base-unit) * 0.25
  );

  --theme-button-tertiary-text: var(--leaf-color-button-tertiary-content);
  --theme-button-tertiary-border: var(--leaf-border-width-button-default) solid
    rgb(0 0 0 / 0); // leaf doesnt have design token for tertiary border default color, i guess since its transparent?
  --theme-button-tertiary-fill: var(--leaf-color-button-tertiary-bg);
  --theme-button-tertiary-hover-text: var(
    --leaf-color-button-tertiary-content-hover
  );
  --theme-button-tertiary-hover-border: var(--leaf-border-width-button-default)
    solid var(--leaf-color-button-tertiary-border-hover);
  --theme-button-tertiary-hover-fill: var(
    --leaf-color-button-tertiary-bg-hover
  );
  --theme-button-tertiary-disabled-text: var(
    --leaf-color-button-tertiary-content-disabled
  );
  --theme-button-tertiary-disabled-border: var(
      --leaf-border-width-button-default
    )
    solid var(--leaf-color-button-tertiary-border-disabled);
  --theme-button-tertiary-disabled-fill: var(
    --leaf-color-button-tertiary-bg-disabled
  );
  --theme-button-tertiary-shadow: none;
  --theme-button-tertiary-focus-shadow: none;
  --theme-button-tertiary-active-outline: var(--leaf-border-width-focus) solid
    var(--leaf-color-border-active-outline);
  --theme-button-tertiary-active-outline-offset: calc(
    var(--size-base-unit) * 0.25
  );

  /* libs/ui/radio */
  --theme-radio-border: 1px solid var(--leaf-color-border-strong);
  --theme-radio-border-checked: 1px solid var(--leaf-color-border-strong);
  --theme-radio-inner-border-checked: none;
  --theme-radio-background-color: var(--leaf-color-content-brand);
  --theme-radio-width: 24px;
  --theme-radio-height: 24px;
  --theme-radio-inner-width: 12px;
  --theme-radio-inner-height: 12px;
  --theme-radio-before-top: 10px;
  --theme-radio-before-left: 6px;
  --theme-radio-focus-box-shadow: none;
  --theme-radio-hover-border: 1px solid var(--leaf-color-border-strong);
  --theme-radio-disabled-border: 1px solid var(--leaf-color-border-disabled);
  --theme-radio-disabled-background-color: var(--leaf-color-bg-disabled);
  --theme-radio-margin-left: 2rem;
  --theme-radio-margin-bottom: 4px;
  --theme-radio-line-height: 2rem;

  /* libs/ui/tabs */
  --theme-tabs-bottom-border: 2px solid var(--leaf-color-border-default);
  --theme-tabs-background: rgb(0 0 0 / 0);
  --theme-tabs-btn-active-background: rgb(0 0 0 / 0);
  --theme-tabs-btn-hover-background-color: var(--leaf-color-bg-brand-hover);
  --theme-tabs-btn-font-weight: var(
    --leaf-typography-label-default-font-weight
  );
  --theme-tabs-btn-font-size: var(--leaf-typography-label-default-font-size);
  --theme-tabs-btn-font-family: var(
    --leaf-typography-label-default-font-family
  );

  --theme-tabs-btn-margin-bottom: 0;
  --theme-tabs-btn-left-margin: 0;

  --theme-tabs-btn-border-radius: 0;
  --theme-tabs-btn-bordered-border-radius: 0;
  --theme-tabs-navbar-left-spacing: 0;

  --theme-tabs-text-transform: none;
  --theme-tabs-link-color: var(--leaf-color-content-link);
  --theme-tabs-link-color-hover: var(--leaf-color-content-link-hover);

  --theme-tabs-btn-text-color: var(--leaf-color-content-link);
  --theme-tabs-btn-text-color-active: var(--leaf-color-content-brand-strong);
  --theme-tabs-btn-hover-text-color: var(--leaf-color-content-link-hover);
  --theme-tabs-btn-hover-underline-color: transparent;

  --theme-tabs-btn-border-bottom: 0;
  --theme-tabs-btn-active-top: 0;

  --theme-tabs-btn-active-border: 0;
  --theme-tabs-btn-active-border-bottom: 2px solid
    var(--leaf-color-content-brand-strong);
  --theme-tabs-btn-active-margin-bottom: -2px;

  /* libs/ui/dropdown */
  --theme-dropdown-checkmark-color: var(--leaf-color-content-link);
  --theme-dropdown-trigger-color: var(--leaf-color-content-link);
  --theme-dropdown-trigger-hover-color: var(--leaf-color-content-link-hover);
  // --theme-dropdown-trigger-box-shadow: none;
  // --theme-dropdown-trigger-border: 1px solid #{$ch-digital-gray-700};
  // --theme-dropdown-menu-font-size: 1rem;
  // --theme-dropdown-menu-border: 1px solid $ch-digital-gray-light;
  --theme-dropdown-menu-border-radius: var(--leaf-border-radius-small);
  --theme-dropdown-menu-padding: 0;

  --theme-dropdown-menu-box-shadow: var(--leaf-shadow-small);

  --theme-dropdown-option-hover-background: var(--leaf-color-bg-brand-hover);
  // --theme-dropdown-option-hover-border-radius: 8px;
  --theme-dropdown-option-padding: 8px 16px 8px 32px;
  // --theme-dropdown-option-checkmark-left: auto;
  // --theme-dropdown-option-checkmark-right: 0px;
  --theme-dropdown-option-checkmark-top: 10px;

  /* libs/ui/collapsible */
  --theme-collapsible-header-link-color: var(--leaf-color-content-link);
  --theme-collapsible-header-border: 1px solid var(--leaf-color-border-strong);
  --theme-collapsible-header-border-expanded: 1px solid
    var(--leaf-color-border-strong);
  --theme-collapsible-header-border-radius: var(--leaf-border-radius-default);
  --theme-collapsible-header-background: #fff;
  --theme-collapsible-header-active-background: #fff;
  --theme-collapsible-header-arrow-color: var(--leaf-color-content-link);

  /* libs/ui/alert */
  --theme-alert-border-width: var(--leaf-border-width-default);
  --theme-alert-border-style: var(--leaf-border-style-default);
  --theme-alert-border-radius: var(--leaf-border-radius-default);
  --theme-alert-icon-height: 24px;
  --theme-alert-icon-width: 24px;

  --theme-alert-info-bg: var(--leaf-color-bg-info);
  --theme-alert-info-border-color: var(--leaf-color-border-info);
  --theme-alert-info-icon-color: var(--leaf-color-content-info-icon);

  --theme-alert-warning-bg: var(--leaf-color-bg-warning);
  --theme-alert-warning-border-color: var(--leaf-color-border-warning);
  --theme-alert-warning-icon-color: var(--leaf-color-content-warning-icon);

  --theme-alert-error-bg: var(--leaf-color-bg-error);
  --theme-alert-error-border-color: var(--leaf-color-border-error);
  --theme-alert-error-icon-color: var(--leaf-color-content-error-icon);

  --theme-alert-success-bg: var(--leaf-color-bg-success);
  --theme-alert-success-border-color: var(--leaf-color-border-success);
  --theme-alert-success-icon-color: var(--leaf-color-content-success-icon);

  /* libs/ui/progress */
  --theme-progress-spinner-font-color: var(--leaf-color-content-default);
  --theme-progress-spinner-border-active: var(--leaf-color-content-brand);
}

a {
  color: var(--leaf-color-content-link);
}

a:active {
  color: var(--leaf-color-content-link-active);
}

a:hover {
  color: var(--leaf-color-content-link-hover);
  cursor: pointer;
}

// a:visited {
//   color: var(--leaf-color-content-link-visited);
// }

// :focus-visible {
//   outline: var(--leaf-color-content-link-hover) auto 1px;
// }

// ::placeholder {
//   color: var(--leaf-color-content-subtle);
// }

input[type='checkbox'] {
  height: 24px;
  width: 24px;
  border-radius: var(--leaf-border-radius-small);
  border-width: var(--leaf-border-width-default);
  border-style: var(--leaf-border-style-default);
  background-color: var(--leaf-color-bg-default);
  border-color: var(--leaf-color-border-strong);
  accent-color: var(--leaf-color-bg-brand-strong);
}
