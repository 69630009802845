/* @theme */
$black: #333333;
$white: #ffffff;

$gray-67: #f4f4f4;
$gray-60: #505050;
$gray-55: #737373;
$gray-50: #969696;
$gray-45: #888888;
$gray-40: #939496;
$gray-15: #d9d9d9;
$gray-07: #adadad;
$gray-06: #c9c9c9;
$gray-05: #e4e4e4;
$gray-03: #f2f6f8;
$gray-02: #f7f7f7;
$gray-01: #f8f8f8;
$gray-65: #5a636b;
$gray-66: #666666;
$gray-70: #adadad;
$gray-80: #d8d8d8;
$gray-90: #9b9b9b;
$gray-100: #767676;
$gray-145: #919191;
$gray-146: #696970;

$digital-black: $black;
$digital-blue: #0170bc;
$digital-blue-01: #fcfeff;
$digital-blue-05: #f3f8fb;
$digital-blue-10: #e6f1f8;
$digital-blue-15: #e5f4fe;
$digital-blue-16: #ddedf8;
$digital-blue-17: #b2d4ea;
$digital-blue-18: #b2defe;
$digital-blue-20: #7ebee4;
$digital-blue-25: #4084b3;
$digital-blue-30: #3378b8;
$digital-blue-40: #2d71b5;
$digital-blue-45: #1171d6;
$digital-blue-43: #cce2f2;
$digital-blue-41: #0094fc;
$digital-blue-42: #0070c2;
$digital-blue-43: #0070b9;
$digital-blue-50: #007bff;
$digital-blue-52: #110081;
$digital-blue-60: #014986;
$digital-blue-70: #2a9fbc;
$digital-blue-80: #f2f9ff;
$digital-blue-90: #eff3f7;
$digital-blue-100: #234381;
$digital-blue-101: #f0f8ff;
$digital-blue-102: #017abc;

$cigna-blue-dark: #004986;
$cigna-blue-light: #00a9e0;
$digital-green: #118503;
$digital-green-light: #a0ce9a;
$digital-green-10: #e7f2e5;
$digital-green-12: #ccefdd;
$digital-green-15: #00ad53;
$digital-green-20: #21b32b;
$digital-green-25: #277a47;
$digital-green-40: #16b058;
$digital-green-45: #4b8d6a;
$green-30: #27871e;
$cigna-orange-logo: #f68621;
$cigna-orange-dark: #e35205;
$cigna-orange-light-20: #fcf0d6;
$cigna-red-light: #f9e5e5;
$digital-red: #cc0000;
$digital-red-01: #c21717;
$digital-red-02: #c14040;
$digital-red-05: #fbe4e3;
$digital-red-10: #ffe3e2;
$digital-red-11: #ff0000;
$digital-red-12: #ba0000;
$digital-purple: #800080;

/* @context */
$border-color: $gray-15;
$link-color: $digital-blue;

$digital-yellow: #f1b434;
$digital-yellow-10: #ffc1078f;
$digital-yellow-20: #ffc1074a;

/* Material Design Specific Color */
$mat-digital-blue-01: #f5fbff;
$mat-digital-blue-03: #e5f0f8;
$mat-digital-blue-04: #cceafe;
$mat-digital-blue-05: #b2c8d7;
$mat-digital-blue-06: #4c7ea1;
$mat-digital-blue-07: #266498;
$mat-digital-blue-08: #f7fafc;
$mat-digital-blue-09: #00487a;
$mat-digital-blue-10: #3f51b5;
$mat-digital-blue-11: #0033ff;

$mat-digital-grey-01: #fafafa;
$mat-digital-grey-02: #f5f8fa;
$mat-digital-grey-03: #f3f3f3;
$mat-digital-grey-04: #cccccc;
$mat-digital-grey-05: #a0a0a0;
$mat-digital-grey-06: #707070;
$mat-digital-grey-07: #b7b7b7;
$mat-digital-grey-08: #585858;
$mat-digital-grey-09: #e7e7e7;
$mat-digital-grey-10: #d6d6d6;

$mat-digital-black-04: #201f1f;
$mat-digital-black-07: #343434;
$mat-digital-black-09: #111111;
$mat-digital-black-10: #000000;

$mat-digital-red-01: #fff4f4;
$mat-digital-red-05: #fd2626;
$mat-digital-red-07: #c21717;
$mat-digital-red-08: #960000;

$mat-digital-green-01: #e5f6ed;
$mat-digital-green-05: #19634b;

$mat-digital-orange-05: #f8a161;
$mat-digital-orange-07: #a34f00;

$gradient-darkblue-top: #0094fc;
