@import 'sass-rem';

// @font-stack
$font-stack: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif;
$font-stack-headings: 'Montserrat', #{$font-stack};
$font-valueSans: 'ValueSansPro';
$font-valueSerif: 'ValueSerifPro';

// @font-sizes
$font-size-xxl: rem-convert(48px);
$font-size-xl: rem-convert(40px);
$font-size-l: rem-convert(24px);
$font-size-ml: rem-convert(20px);
$font-size-mml: rem-convert(18px);
$font-size-m: rem-convert(16px);
$font-size-s: rem-convert(14px);
$font-size-xs: rem-convert(12px);

$header-size-xl: rem-convert(36px);
$header-size-lg: rem-convert(28px);
$header-size-md: rem-convert(22px);
$header-size-sm: rem-convert(18px);
$header-size-xs: rem-convert(16px);

$header-line-xl: rem-convert(40px);
$header-line-lg: rem-convert(32px);
$header-line-md: rem-convert(28px);
$header-line-sm: rem-convert(24px);

// @header sizes from h1 to h4
$heading-sizes: $header-size-xl, $header-size-lg, $header-size-md,
  $header-size-sm;
