@use '@esi/leaf-design-tokens/lib/web/mixins/typography-usage.scss' as
  typography;

$asset-prefix: 'https://chk.static.cigna.com/spa/chcp/assets/';

/* NEW THEME - Leaf */
@import 'themes/leaf';

/* retire once we remove all font variables */
@import 'variables';

/* do we still need this? */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* general html resets */

html {
  text-size-adjust: 100%;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0;
  padding: 0;
}

body,
div,
fieldset,
form,
html,
label,
legend {
  margin: 0;
  padding: 0;
}

fieldset,
legend {
  border: 0;
}

main,
section,
header,
footer,
nav,
article,
aside {
  display: block;
}

/* we have our own reveal pw control now so we can disable microsoft edge implementation */
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

select[multiple] option {
  padding: 8px;
}

/* page container grid */

.cg-main-page-container {
  display: grid;
  // assumes <header>, <main>, <footer> are all siblings and no other top level elements
  grid-template-areas: 'header' 'main' 'footer';
  grid-template-rows: fit-content(2rem) auto fit-content(4rem);
  height: 100vh;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
  padding-bottom: 4em;
  padding-top: 3em;
  background-color: #fff;
}

footer {
  grid-area: footer;
}

/* flex utils */

.flex-row,
.cg-flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column,
.cg-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-vertical-center,
.cg-flex-vertical-center {
  align-items: center;
}

.content-rail,
.cg-inner-page-wrapper {
  max-width: 1380px;
  margin: 0 auto 0 auto;
  padding: 0 16px;
}

.cg-page-blue-top {
  padding-top: 3em;
  background-color: var(--leaf-color-bg-subtle);
}

/* leaf typography */
.cg-leaf-label-small {
  @include typography.leaf-typography-label-small;
}

.cg-leaf-label-default {
  @include typography.leaf-typography-label-default;
}

.cg-leaf-meta-small {
  @include typography.leaf-typography-meta-small;
}

.cg-leaf-card {
  background-color: var(--leaf-color-bg-default);
  border: 1px solid var(--leaf-color-border-default);
  border-radius: var(--leaf-border-radius-default);
}

/* https://dev-digital.cigna.com/foundations/design-foundations/typography/ */

.cg-h1 {
  // font-size: 30px;
  // /* using 30 instead of 36 for now */
  // font-weight: 500;
  // word-spacing: normal;
  margin: 0 0 1em 0;

  @include typography.leaf-typography-headline-large;
  color: var(--leaf-color-content-brand-strong);
}

.cg-h2 {
  font-size: 28px;
  word-spacing: normal;
}

.cg-h3 {
  font-family: var(--leaf-typography-title-default-font-family);
  font-size: var(--leaf-typography-title-default-font-size);
  font-weight: var(--leaf-typography-title-default-font-weight);
  line-height: var(--leaf-typography-title-default-line-height);
}

.cg-h4 {
  font-size: var(--leaf-typography-label-default-font-size);
  word-spacing: normal;
}

.cg-h5 {
  font-size: 16px;
  word-spacing: normal;
}

.cg-font-size-xl {
  font-size: $font-size-xl;
}

.cg-font-size-l {
  font-size: $font-size-l !important;
}

.cg-font-size-ml {
  font-size: $font-size-ml !important;
}

.cg-font-size-mml {
  font-size: $font-size-mml !important;
}

.cg-font-size-m {
  font-size: $font-size-m !important;
}

.cg-font-size-s {
  font-size: $font-size-s !important;
}

.cg-font-size-xs {
  font-size: $font-size-xs !important;
}

.cg-header-subtext {
  font-weight: normal;
  font-size: 1em;
}

.cg-subhead-1,
.cg-subhead-3,
.cg-subhead-4 {
  font-family: var(--leaf-typography-body-default-font-family);
}

.cg-subhead-1 {
  font-weight: 500;
  font-size: $header-size-lg;
  line-height: $header-line-lg;
}

.cg-subhead-3 {
  font-weight: 500;
  font-size: $header-size-md;
  line-height: $header-line-md;
}

.cg-subhead-4 {
  font-weight: 500;
  font-size: $header-size-sm;
  line-height: $header-line-sm;
}

.cg-callout-header {
  display: inline-block;
}

.cg-small-note {
  color: var(--leaf-color-content-subtle);
  font-size: $font-size-xs;
  font-weight: normal;
}

.cg-capitalize {
  text-transform: capitalize;
}

.cg-clearfix::after,
.cg-clearfix::before {
  content: ' ';
  display: table;
}

.cg-clearfix::after {
  clear: both;
}

.cg-media,
.cg-media__bd {
  overflow: hidden;
}

.cg-media__img {
  float: left;
  margin-right: 0.5em;
}

.cg-pipe-link {
  padding: 0 0.5em;
}

.cg-pipe-style {
  color: var(--leaf-color-border-strong);
  padding: 0 0.5em;
}

.cg-pipe-right {
  border-right: 1px solid var(--leaf-color-border-strong);
  padding-right: 1em;
  margin-right: 1em;
}

.cg-pipe-left {
  border-left: 1px solid var(--leaf-color-border-strong);
  padding-left: 1em;
  margin-left: 1em;
}

.cg-button-group {
  display: flex;
  align-items: center;
}

.cg-button-group [cignaButton] {
  margin-left: 1em;
}

.cg-button-group [cignaButton]:first-child {
  margin-left: 0;
}

.cg-highlight {
  background-color: #fcf0d6;
}

/* margins */
.cg-margin-top-none {
  margin-top: 0 !important;
}

.cg-margin-top-xs {
  margin-top: 0.25em;
}

.cg-margin-top-sm {
  margin-top: 0.5em;
}

.cg-margin-top-md {
  margin-top: 1em;
}

.cg-margin-top-lg {
  margin-top: 1.5em;
}

.cg-margin-top-xl {
  margin-top: 2em;
}

.cg-margin-top-xxl {
  margin-top: 3em;
}

.cg-margin-bottom-none {
  margin-bottom: 0 !important;
}

.cg-margin-bottom-xs {
  margin-bottom: 0.25em;
}

.cg-margin-bottom-sm {
  margin-bottom: 0.5em;
}

.cg-margin-bottom-md {
  margin-bottom: 1em;
}

.cg-margin-bottom-lg {
  margin-bottom: 1.5em;
}

.cg-margin-bottom-xl {
  margin-bottom: 2em;
}

.cg-margin-bottom-xxl {
  margin-bottom: 3em;
}

.cg-margin-left-xs {
  margin-left: 0.25em;
}

.cg-margin-left-sm {
  margin-left: 0.5em;
}

.cg-margin-left-md {
  margin-left: 1em;
}

.cg-margin-left-lg {
  margin-left: 1.5em;
}

.cg-margin-left-xl {
  margin-left: 2em;
}

.cg-margin-right-xs {
  margin-right: 0.25em;
}

.cg-margin-right-sm {
  margin-right: 0.5em;
}

.cg-margin-right-md {
  margin-right: 1em !important;
}

.cg-margin-right-lg {
  margin-right: 1.5em;
}

.cg-margin-right-xl {
  margin-right: 2em;
}

.cg-padding-top-sm {
  padding-top: 0.5em;
}

.cg-padding-top-md {
  padding-top: 1em;
}

.cg-padding-top-lg {
  padding-top: 1.5em;
}

.cg-padding-top-xl {
  padding-top: 2em;
}

.cg-display-none {
  display: none !important;
}

.cg-padding-top-none {
  padding-top: 0 !important;
}

.cg-padding-bottom-none {
  padding-bottom: 0;
}

hr {
  margin: 1em 0;
  border: 1px solid var(--leaf-color-border-default);
}

/* colors */
.cg-color-warn {
  color: var(--leaf-color-bg-warning-strong);
}

.cg-color-info {
  color: var(--leaf-color-content-info-icon);
}

.cg-color-severe-warn {
  color: var(--leaf-color-content-error);
}

.cg-color-success {
  color: var(--leaf-color-content-success);
}

.cg-link-color {
  color: var(--leaf-color-content-link);
}

/* lists */
.cg-bullet-list {
  margin: 0.5em 0 1em 0;
  padding: 0;
}

.cg-bullet-list__item {
  margin-left: 15px;
  margin-bottom: 0.2em;
}

.cg-bullet-list__item--circle {
  list-style-type: circle;
}

.cg-bullet-list__item--none {
  list-style: none;
  margin-left: 0;
}

.cg-ordered-list {
  margin: 0.5em 0 1em 0;
  padding: 0;
}

.cg-ordered-list__item {
  margin-left: 15px;
  margin-bottom: 0.2em;
}

.cg-compare-list {
  margin: 0.5em 0 1em 10px;
  padding: 0;
  list-style: none;
}

.cg-compare-list__item--same {
  margin-bottom: 0.3em;
}

.cg-compare-list__item--added {
  position: relative;
  margin-bottom: 0.3em;
}

.cg-compare-list__item--added::before {
  content: '+';
  position: absolute;
  left: -10px;
}

.cg-compare-list__item--removed {
  position: relative;
  margin-bottom: 0.3em;
}

.cg-compare-list__item--removed::before {
  content: '-';
  position: absolute;
  left: -10px;
}

.cg-compare-list__item--skipped::before {
  content: none;
  position: absolute;
  left: -10px;
}

.cg-compare-list__item--skipped {
  position: relative;
  margin-bottom: 0.3em;
  color: orange;
}

/* multi column lists */

.cg-multicolumn-list {
  column-gap: 1em;
  column-count: 1;
}

.cg-multicolumn-list__item {
  page-break-inside: avoid;
  break-inside: avoid;
  /* fix column spreading bug in IE11, also fixes checkbox focus overflow for chrome */
  overflow: hidden;
}

@media only screen and (min-width: 61.75em) {
  .cg-multi-column-list-lg-2 {
    column-count: 2;
  }

  .cg-multi-column-list-lg-3 {
    column-count: 3;
  }

  .cg-multi-column-list-lg-4 {
    column-count: 4;
  }
}

/* background blocks */
.cg-contrast-block-on-dark {
  padding: 1em;
  background-color: #ffffff;
}

.cg-contrast-block-on-light {
  padding: 1em;
  background-color: #f9f9f9;
}

/* input table */

.cg-input-table {
  width: 100%;
  table-layout: fixed;
}

.cg-input-table th {
  font-weight: normal;
  padding-bottom: 0.5em;
  text-align: left;
}

.cg-input-table tbody td {
  padding: 0.75em 0;
}

.cg-input-table .cg-field-container {
  margin-bottom: 0;
}

td,
th {
  vertical-align: top;
}

/* data table */

.cg-datatable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse !important;
}

.cg-datatable th {
  z-index: 2;
  text-align: left;
  vertical-align: middle;
  font-weight: bold;
}

.cg-datatable__top {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: $gray-05;
  border-radius: 3px 3px 0 0;
}

.cg-datatable__top select,
.cg-datatable__top input {
  margin-left: 5px;
  box-shadow: none !important;
}

.cg-datatable__top-right {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.cg-datatable__header,
.cg-datatable__header--sortable {
  background-color: var(--leaf-color-bg-default);
  border-top: 1px solid var(--leaf-color-border-strong);
  border-bottom: 1px solid var(--leaf-color-border-strong) !important;
  font-family: var(--leaf-typography-label-default-font-family);
  font-size: var(--leaf-typography-label-default-font-size);
  font-weight: var(--leaf-typography-label-default-font-weight);
  color: inherit;
}

.cg-datatable__header--sortable {
  color: var(--leaf-color-content-link);
  cursor: pointer;
  padding: 15px 7px;
}

.cg-datatable__header {
  position: sticky;
  top: 0;
}

.cg-datatable__header-checkbox {
  vertical-align: middle;
  text-align: center;
}

.cg-datatable__header-checkbox-input {
  margin: 0.25em auto;
  display: block;
}

.mat-sort-header-container,
.nosort-header-container {
  padding: 15px 7px;
}

.cg-datatable__cell {
  border-top: 1px solid var(--leaf-color-border-default);
  border-bottom: 1px solid var(--leaf-color-border-default);
  padding: 10px 7px;
  line-height: 1.4;
  word-wrap: break-word;
}

.cg-datatable__cell--centered {
  text-align: center;
}

.cg-datatable__cell--noresults {
  padding: 3em;
  text-align: center;
}

.cg-datatable--borderless .cg-datatable__header,
.cg-datatable--borderless .cg-datatable__cell,
.cg-datatable--borderless.cg-datatable {
  border: 0 !important;
}

.cg-plain-data-table {
  width: 100%;
  table-layout: fixed;
}

.cg-plain-data-table tr > th {
  font-weight: bold;
  text-align: left;
}

.cg-plain-data-table tr td {
  word-wrap: break-word;
}

.cg-plain-data-table tr td,
.cg-plain-data-table tr th {
  padding: 0.5em 0;
}

.cg-plain-data-table tr.cg-plain-data-table__row-gap th,
.cg-plain-data-table tr.cg-plain-data-table__row-gap td {
  padding-top: 1.5em;
}

/*  lightboxes */

.cg-dialog {
  color: #333;
  border-radius: 5px;
}

.cg-dialog__header {
  margin: 0 0 32px 0;
  padding: 0 110px 0 0;
  background: transparent;
  color: var(--leaf-color-content-brand-strong);
  font-size: var(--leaf-typography-title-large-font-size);
  font-weight: var(--leaf-typography-title-large-font-weight);
  font-family: var(--leaf-typography-title-large-font-family);
  line-height: var(--leaf-typography-title-large-line-height);
  position: relative;
}

.cg-dialog__header_subhead {
  font-size: 14px;
  font-family: $font-stack;
  display: block;
  font-weight: normal;
  margin-top: 0.5em;
}

.cg-dialog__header--modal {
  border-bottom: 0;
}

.cg-dialog p {
  margin-top: 0;
}

.cg-dialog__close_new {
  margin: 0;
  position: absolute;
  font-size: $font-size-m !important;
  right: 0;
  top: 8px;

  .cml-close-icon {
    line-height: 12px;
    margin-left: 4px;
    height: 16px;
    width: 16px;
  }
}

.cg-text-center {
  text-align: center;
}

.cg-flex {
  display: flex;
}

.cg-flex-column {
  display: flex;
  flex-direction: column;
}

.cg-flex-vertical-center {
  display: flex;
  align-items: center;
}

.cg-flex-start {
  display: flex;
  align-items: flex-start;
}

.cg-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cg-flex-item-equal-width {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.cg-float-right {
  float: right;
}

.cg-float-left {
  float: left;
}

.cg-fieldset {
  padding-bottom: 0 !important;
  clear: none;
}

.cg-field-container {
  margin-bottom: 1em;
  text-align: left;
}

.cg-field-container__label {
  display: block;
}

.cg-field-container__label,
.cg-field-container label {
  text-decoration: var(--leaf-typography-label-small-text-decoration);
  text-transform: var(--leaf-typography-label-small-text-case);
  letter-spacing: var(--leaf-typography-label-small-letter-spacing);
  font-size: var(--leaf-typography-label-small-font-size);
  line-height: var(--leaf-typography-label-small-line-height);
  font-weight: var(--leaf-typography-label-small-font-weight);
  font-family: var(--leaf-typography-label-small-font-family);
  margin-bottom: calc(var(--size-base-unit) * 1);
}

.cg-field-container__label--disabled {
  color: #adadad;
}

.cg-field-container-inline-label {
  display: inline-block !important;
}

.cg-field-container__input-container {
  float: none;
}

.cg-field-container__input-container-underline {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #cccccc;
}

.cg-field-container__side-note {
  padding-left: 0;
  margin-top: 5px;
  line-height: 17px;
  display: inline-block;
}

.cg-error-msg,
.cg-error-msg-large {
  display: inline-flex;
  color: var(--leaf-color-content-error);
}

.cg-error-msg {
  line-height: 18px;
  margin-top: 5px;
}

.cg-error-msg-large {
  line-height: 24px;
}

.cg-button-group .cg-error-msg {
  margin-top: 0;
}

button.cg-button-appearance-none {
  background: none;
  border: none;
  margin: inherit;
  padding: inherit;
  text-align: inherit;
  font-size: var(--leaf-typography-body-default-link-font-size);
  font-family: var(--leaf-typography-body-default-font-family);
  font-weight: var(--leaf-typography-body-default-font-weight);
}

.cg-error-msg cigna-icon {
  margin-right: 8px;
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.cg-error-msg mat-icon {
  margin-right: 8px;
  height: 16px;
  width: 16px;
  min-width: 16px;
}

.cg-error-msg-large cigna-icon {
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: 8px;
}

.cg-multiple-field-container .cg-field-container {
  float: left;
  margin-right: 2em;
}

.cg-multiple-field-container::after {
  display: block;
  clear: both;
  content: ' ';
}

.cg-indent-label-text {
  margin-left: 1.7em;
  display: block;
  line-height: 1;
  margin-bottom: 1.5em;
  padding-top: 5px;
}

.cg-indent-label-text--compact {
  margin-bottom: 0.5em;
}

.cg-indent-label-text--with-input {
  display: inline-block;
}

.cg-indent-label-text__input {
  float: left;
  margin: 0 0 0 -1.6em;
  padding: 0;
}

/* icons */

.cg-mat-icon-link-right .mat-icon,
mat-icon.cg-mat-icon-link-right {
  height: 15px;
  width: 15px;
  vertical-align: top;
  margin-left: 6px;
}

.cg-mat-icon-link-left .mat-icon,
mat-icon.cg-mat-icon-link-left {
  height: 15px;
  width: 15px;
  vertical-align: top;
  margin-right: 6px;
}

.cg-header-close {
  position: relative;
  float: right;
}

/* LOADING SPINNERS */

.cg-spinner {
  border-width: 5px;
  border-left-color: rgb(0 51 255 / 0.15);
  border-right-color: rgb(0 51 255 / 0.15);
  border-bottom-color: rgb(0 51 255 / 0.15);
  border-top-color: rgb(0 51 255 / 1);
  border-style: solid;
  box-sizing: content-box;
  height: 18px;
  width: 18px;
  position: relative;
  animation: rotation 0.7s infinite linear;
  border-radius: 100%;
}

.cg-spinner-small {
  height: 12px;
  width: 12px;
  border-width: 3px;
}

.cg-spinner-large {
  height: 36px;
  width: 36px;
  border-width: 7px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.cg-spinner-inline .cg-spinner {
  display: inline-block;
  margin-right: 5px;
}

.cg-show-for-print {
  display: none;
}

/* legacy crazy selectors */

.cg-collapse-datatable {
  width: 100%;
  table-layout: fixed;
  font-size: 12px;
  border-collapse: collapse;
  text-align: left;
  .header {
    background-color: #e8f9fe;
    font-weight: normal;
    border-bottom: 1px solid #d0d0d0;
    color: #333;
    position: sticky;
    top: 0;
    th {
      padding: 10px 5px;
      text-align: left;
    }
  }
  .header.enhanced-header {
    background-color: #f8f8f8;
    th:first-child {
      padding-left: 1.25rem;
    }
  }
  .body {
    background-color: #f9f9f9;
    padding: 10px 7px;
    line-height: 1.4;
    word-wrap: break-word;
    td {
      padding: 10px 5px;
      border-bottom: 1px solid #d0d0d0;
    }
    p {
      margin: 0;
    }
    ul {
      padding: 0 15px;
      margin: 0;
    }
  }
  .body.enhanced-body {
    background-color: transparent;
    td:first-child {
      padding-left: 1.25rem;
    }
  }
}

.enhanced-notes {
  padding-left: 1.25rem !important;
}

/* print */
@media print {
  .cg-hide-for-print {
    display: none !important;
  }

  .cg-show-for-print {
    display: block;
  }

  .cg-print-item-fullpage {
    height: 100vh;
    page-break-before: always;
    page-break-after: always;
  }

  .cg-print-page-break-after {
    page-break-after: always;
  }

  .cg-print-page-break-inside {
    display: block;
    page-break-inside: avoid;
  }

  .cg-print-hide-table-borders .cg-datatable__cell {
    border-bottom: 0;
    border-right: 0;
  }

  .cg-print-stack-table-cells .cg-datatable__cell {
    display: table-row;
  }

  .collapsible-block__header,
  .cg-collapsible-group__action,
  .collapsible-block__content button,
  cigna-patient-useful-section {
    display: none;
  }

  .collapsible-block .collapsible-block__header--active {
    display: block;
  }

  .collapsible {
    display: none;
  }

  .collapsible.collapsible--expanded {
    display: block;
  }

  /* overriding matdialog modal styles for print */
  .cdk-global-scrollblock {
    top: 0 !important;
    position: static !important;
  }

  .cdk-global-scrollblock .cdk-overlay-container {
    position: static !important;
  }

  .cdk-global-scrollblock cigna-root {
    display: none !important;
  }

  .cdk-global-overlay-wrapper {
    position: static;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .mat-dialog-container {
    overflow: visible !important;
    border-radius: var(--leaf-border-radius-container) !important;
    box-shadow: none;
  }

  .mat-dialog-content {
    overflow: visible !important;
    max-height: initial !important;
  }

  .cdk-overlay-pane {
    width: 100% !important;
    max-width: inherit !important;
  }
}

/* angular cdk */
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  appearance: none;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  box-sizing: border-box;
}

.cdk-overlay-backdrop,
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  z-index: 1000;
}

.cdk-overlay-backdrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.theme-leaf .cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 0.25);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.mat-dialog-container {
  background: #fff;
  padding: 32px !important;
  border-radius: var(--leaf-border-radius-container) !important;
  max-height: 90vh;
  box-shadow: none;
}

.mat-dialog-content {
  padding: 0 !important;
  margin: 0 !important;
}

.theme-leaf .mat-mdc-dialog-container .mdc-dialog__container {
  transition-duration: 0ms;
}

.theme-leaf .mat-mdc-dialog-container {
  background: #fff;
  padding: 32px;
  border-radius: var(--leaf-border-radius-container);
  max-height: 90vh;
}

.theme-leaf .mdc-dialog .mdc-dialog__content {
  padding: 0;
  margin: 0;
}

.theme-leaf .mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: none !important;
  border-radius: 0;
}

.theme-leaf .mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
}

.theme-leaf .mat-sort-header-button {
  box-shadow: none;
  text-transform: none;
  text-align: left;
}

.theme-leaf .mat-sort-header-button:hover {
  background-color: inherit;
}

button.mat-icon-button:hover,
button.mat-icon-button:active,
button.mat-button:hover {
  background-color: transparent;
}

.mat-icon-button .mat-button-wrapper cigna-icon {
  vertical-align: initial;
}

.mat-icon-button[disabled][disabled] cigna-icon {
  color: $gray-05;
}

.cg-datepicker-wrap .mat-datepicker-toggle mat-icon {
  color: var(--leaf-color-content-link);
  --mdc-icon-button-icon-size: 20px;
}

.cg-datepicker-wrap {
  display: inline-block;
  position: relative;
}

.cg-datepicker-wrap input.mat-datepicker-input {
  padding-right: 35px;
  width: 150px;
}

.cg-datepicker-wrap .mat-datepicker-toggle {
  position: absolute;
  right: 0;
  top: 0;
}

.theme-leaf .mat-calendar-body-selected {
  background-color: var(--leaf-color-content-link);
}

.theme-leaf
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: var(--leaf-color-bg-brand);
}

.theme-leaf .mat-button-ripple.mat-ripple {
  display: none;
}

.theme-leaf .mat-sort-header-container .mat-sort-header-content {
  text-align: left;
}

.mat-mdc-option.mdc-list-item {
  display: block;
  line-height: 48px;
  height: 48px;
}

/* colors */

/* https://wc5y6a.axshare.com/#g=1&p=colors */

.cg-color-gray-60 {
  color: #505050;
}

.cg-color-gray-55 {
  color: $gray-55;
}

.cg-color-gray-40 {
  color: #939496;
}

.cg-color-gray-15 {
  color: #d9d9d9;
}

.cg-color-gray-05 {
  color: #e4e4e4;
}

.cg-color-gray-01 {
  color: #f8f8f8;
}

.cg-color-white {
  color: #fff;
}

.cg-color-digital-blue-10 {
  color: #e6f1f8;
}

.cg-color-digital-blue-dark {
  color: #004986;
}

.cg-color-digital-blue-light {
  color: #00a9e0;
}

.cg-color-success-10 {
  color: #e7f2e5;
}

.cg-color-digital-orange-light-20 {
  color: #fcf0d6;
}

.cg-color-digital-orange-logo {
  color: $cigna-orange-logo;
}

.cg-color-digital-red-10 {
  color: #ffe3e2;
}

.cg-color-digital-purple {
  color: #800080;
}

.field-width-xxl {
  width: 500px;
}

.field-width-max-lg {
  max-width: 350px;
}

.field-width-max-md {
  max-width: 245px;
}

.field-width-max-sm {
  max-width: 188px;
}

.field-width-max-xs {
  max-width: 80px;
}

.field-width-max-xxs {
  max-width: 60px;
}

.field-width-lg {
  width: 350px !important;
}

.field-width-md {
  width: 245px !important;
}

.field-width-sm {
  width: 188px !important;
}

.field-width-xs {
  width: 80px !important;
}

.field-width-xxs {
  width: 60px !important;
}

.dash {
  margin: auto 3px;
}

.cigna-icon {
  height: 16px;
  width: 16px;
}

.chcp-radio {
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0;
}

.chcp-radio input[type='radio'] {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  border: 0;
  left: 0;
}

.chcp-radio .chcp-radio-img {
  display: flex;
  align-self: center;
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: #fff;
  border: 1px solid var(--leaf-color-border-strong);
  cursor: pointer;
}

.chcp-radio .chcp-radio-label {
  display: flex;
  align-self: center;
  position: relative;
  font-size: var(--leaf-typography-body-default-font-size);
  padding-left: 12px;
  font-weight: 500;
}

.chcp-radio input[type='radio']:checked + .chcp-radio-img::after {
  background-color: var(--leaf-color-content-brand);
  content: '';
  display: block;
  position: absolute;
  left: 5px;
  top: 5px;
  height: 12px;
  width: 12px;
  border-radius: 12px;
}

.chcp-radio input[type='radio'][disabled]:checked + .chcp-radio-img::after {
  background-color: var(--leaf-color-border-disabled);
  border: 1px solid var(--leaf-color-border-disabled);
}

.cg-checkbox-label {
  display: flex;
  align-items: center;
}
.cg-checkbox-label input[type='checkbox'] {
  margin: 3px;
  padding: 0;
  margin-right: 8px;
}

.cg-icon-xxl {
  height: 100px !important;
  width: 100px !important;
}

.cg-icon-xl {
  height: 50px !important;
  width: 50px !important;
}

.cg-icon-lg {
  height: 30px !important;
  width: 30px !important;
}

.cg-icon-md {
  height: 24px !important;
  width: 24px !important;
}

.cg-icon-sm {
  height: 20px !important;
  width: 20px !important;
}
.cg-icon-xs {
  height: 15px !important;
  width: 15px !important;
}

.cg-clickable-icon {
  cursor: pointer;
}

.cg-non-clickable {
  pointer-events: none;
}

.cg-disabled-link {
  color: $gray-55 !important;
}

.cg-flush-button-link {
  padding-left: 0 !important;
}

.cg-nopadding-button-link {
  padding: 0 !important;
}

.cg-loading-icon-block {
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

/* override default fixed width when using cignaSelect */
.cg-select-width-auto {
  width: auto !important;
  max-width: 100%;
}

@media print {
  a {
    color: #000000 !important;
  }
  .cg-datatable__header {
    position: static;
  }
}

select[cignaSelect][multiple] {
  background-image: none;
  padding-right: 0.5rem;
}

/* default placeholder color is light grey, can override in individual component scoped scss if needed */
cigna-skeleton {
  opacity: 0.1;
}

.cg-collapsible--full-bleed {
  margin: 0 -1.25rem;
}

.cg-collapsible--full-bleed .cg-datatable--borderless thead tr th:first-child,
.cg-collapsible--full-bleed .cg-datatable--borderless tbody tr td:first-child {
  padding-left: 1.25rem;
}

.cg-collapsible--full-bleed .cg-datatable--borderless thead tr th:last-child,
.cg-collapsible--full-bleed .cg-datatable--borderless tbody tr td:last-child {
  padding-right: 1.25rem;
}

.cg-bold {
  font-weight: bold;
}

.cg-borderless-iframe {
  border: 0;
  padding: 0;
}

// shared popover styles
.popover {
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(51 51 51 / 0.2);
  border-radius: 0.3rem;
}

.popover,
.popover .arrow {
  position: absolute;
  display: block;
}

.popover .arrow {
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^='top'],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^='top'] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='top'] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgb(51 51 51 / 0.25);
}

.bs-popover-auto[x-placement^='top'] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^='right'] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='right'] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgb(51 51 51 / 0.25);
}

.bs-popover-auto[x-placement^='right'] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^='bottom'] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[x-placement^='bottom'] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: rgb(51 51 51 / 0.25);
}

.bs-popover-auto[x-placement^='bottom'] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^='left'],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^='left'] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='left'] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgb(51 51 51 / 0.25);
}

.bs-popover-auto[x-placement^='left'] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #333;
}
