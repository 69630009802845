@import 'chcp-assets';

body {
  // temp addition until leaf fixes line height issue: https://github.com/cigna-group/leaf-web-components/issues/74
  --leaf-typography-body-default-line-height: 1.5;
}

// fix for 1s that look like i's, and zeroes that look identical to O's
// hopefully they update font for cigna healthcare to something better that doesn't need this anymore
body,
input,
textarea,
button,
label,
select,
a {
  font-variant-numeric: slashed-zero tabular-nums !important;
}
